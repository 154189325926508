import "./style.css";

const WelcomePage = ({ user, setIsLoggedIn }) => {
  const HandleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="container">
      <span>Bem vindo, {user}</span>
      <button onClick={HandleLogout}>Clique em mim</button>
    </div>
  );
};

export default WelcomePage;
